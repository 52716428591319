<template lang="html">
  <div class="">
    Auth
  </div>
</template>

<script>
export default {

};
</script>
